.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

body {
  color: #6D624E;
  background: #E5E0C2;
}

input[type=text],
input[type=number] {
  color: #786032;
  background: #D5C68B;
  border: none;
  border-radius: 5px;
  outline: none;
}

button {
  background: #F5F7E1;
  color: #8D7C68;
  border: none;
  border-radius: 20px;
  outline: none;
  padding: 10px 20px;
}
